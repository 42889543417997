define("@heroku/ember-hk-components/components/hk-field-validations", ["exports", "@ember-decorators/component", "@ember/component", "@ember/destroyable", "@ember/object/compat", "@glimmer/tracking", "@heroku/ember-hk-components/templates/components/hk-field-validations", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _component2, _destroyable, _compat, _tracking, _hkFieldValidations, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @import {Changeset} from "ember-changeset"
   */
  /**
   * @typedef {HkFieldValidations & { property: string; changeset: ReturnType<Changeset>; debounceValidation?: number }} This
   */
  let HkFieldValidations = _exports.default = (_dec = (0, _component.layout)(_hkFieldValidations.default), _dec2 = (0, _component.attributeBindings)('data-test-target'), _dec3 = (0, _component.className)('has-validation-errors'), _dec4 = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), _dec(_class = _dec2(_class = (_class2 = class HkFieldValidations extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * Possible states:
       *
       * - "initial": the user has not yet changed the field or the field is empty.
       *   While in the initial state, we don't want to show any validation errors.
       * - "validating": the user has modified the field, and either the 1 second
       *   countdown to validation has begun or the validation is async and is in
       *   progress.
       * - "validated": the user has modified the field and the validation has
       *   completed.
       *
       * If a field is changed back to its initial value, it is returned to the
       * "initial" state, and any pending validations are cancelled.
       *
       * @type {"initial" | "validating" | "validated"}
       */
      _initializerDefineProperty(this, "state", _descriptor, this);
    }
    /**
     * @this {This}
     * @param  {Parameters<Component["didInsertElement"]>} args
     */
    didInsertElement(...args) {
      super.didInsertElement(...args);
      const afterValidation = key => {
        if (this.property === key) {
          this.state = 'validated';
        }
      };
      this.changeset.on('afterValidation', afterValidation);
      (0, _destroyable.registerDestructor)(this, () => this.changeset.off('afterValidation', afterValidation));
    }

    /** @this {This} */
    get changesetErrors() {
      return this.changeset.errors;
    }

    /** @this {This} */
    get isValid() {
      return this.state === 'validated' && !this.hasValidationErrors;
    }

    /** @this {This} */
    get isInvalid() {
      return this.state === 'validated' && this.hasValidationErrors;
    }

    /**
     * @this {This}
     * @private
     */
    get hasValidationErrors() {
      return this.validationErrors && this.validationErrors.validation.length > 0;
    }

    /** @this {This} */
    get validationErrors() {
      const prop = this.property;
      const error = this.changeset.errors.find(error => error.key === prop);
      if (!error || typeof error.validation === 'object' && error.validation !== null && typeof error.validation.then === 'function') {
        return {
          validation: []
        };
      }
      return {
        validation: flattenErrors(error.validation)
      };
    }

    /** @this {This} */
    get value() {
      return this.changeset[this.property];
    }

    /**
     * @private
     */
    get original() {
      return this.changeset.data[this.property];
    }

    /** @this {This} */
    set value(value) {
      const original = this.changeset.data[this.property];
      const dirty = !isEquivalent(original, value);
      this.changeset[this.property] = value;
      if (dirty) {
        this.validateProperty.perform();
      } else {
        this.state = 'initial';
        this.validateProperty.cancelAll();
      }
    }

    /**
     * Validate the property, but only after the user has stopped typing for
     * at least 1 second.
     *
     * @private
     */
    *validateProperty() {
      this.state = 'validating';
      yield (0, _emberConcurrency.timeout)(this.debounceValidation ?? 1000);
      this.state = 'validated';
      return this.changeset.validate(this.property);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "state", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'initial';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "changesetErrors", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "changesetErrors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isInvalid", [_dec3, _compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "isInvalid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "validationErrors", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "validationErrors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "value", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "value", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "validateProperty", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "validateProperty"), _class2.prototype), _class2)) || _class) || _class);
  /** @typedef {string | string[]} ValidationError */

  /**
   * @param {ValidationError | ValidationError[]} validation
   * @returns {string[]}
   */
  function flattenErrors(validation) {
    if (typeof validation === 'string') {
      return [validation];
    }
    return validation.flatMap(flattenErrors);
  }

  /**
   * @param {unknown} a
   * @param {unknown} b
   */
  function isEquivalent(a, b) {
    return (a ?? '') === (b ?? '');
  }
});