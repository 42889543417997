define("ember-svg-jar/inlined/flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18.965 2A2.04 2.04 0 0121 4.034a2.04 2.04 0 01-2.035 2.035H19h-.166v6.818c3.46 2.244 7.18 6.104 7.165 9.753-.008 1.85-1.48 3.36-3.274 3.36h-.513L5.26 25.93c-1.8-.007-3.268-1.53-3.26-3.387.015-3.639 3.776-7.454 7.193-9.673V6.07h-.158A2.04 2.04 0 017 4.034 2.04 2.04 0 019.035 2zM9.166 20.894H4.204a4.75 4.75 0 00-.218 1.362 1.797 1.797 0 001.808 1.84l16.425.069c1.004 0 1.82-.857 1.824-1.91a4.998 4.998 0 00-.193-1.362H13.736a2.3 2.3 0 01-4.57 0zm2.285-1.267c-.558 0-1.012.454-1.012 1.011a1.013 1.013 0 002.024 0c0-.557-.453-1.011-1.012-1.011zm5.562-13.592h-6.016v7.575l-.71.482c-2.25 1.461-4.282 3.35-5.399 5.27H9.54a2.297 2.297 0 013.823 0h9.84c-.86-1.53-2.504-3.327-5.473-5.253l-.717-.448V6.035zm-3.263 7.188a2.043 2.043 0 110 4.087 2.043 2.043 0 010-4.087zm0 1.253a.79.79 0 10.79.79.791.791 0 00-.79-.79zm5.206-11.02H9.18c-.331 0-.611.265-.611.578 0 .313.28.578.611.578h9.777c.332 0 .612-.265.612-.578 0-.313-.28-.577-.612-.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
});